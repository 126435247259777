// import { GUTTER } from '../configs/consts'
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { X } from 'react-feather';

import { BREAKPOINTS } from '../configs/consts';

const GUTTER = 20;

const RightPanel = (props) => {
  const [show, setShow] = useState(false);

  const closePanel = () => {
    props.control.setIsOpen(false);
  }

  useEffect(() => {
    if (props.control.isOpen) {
      document.body.style.overflow = 'hidden';
      setShow(props.control.isOpen);
    } else {
      document.body.style.overflow = 'initial';
      setShow(props.control.isOpen);
    }
  }, [props.control.isOpen])

  return (show &&
    <StyledRightPanel {...props} gutter={GUTTER} show={show}>
      <div className='outside' onClick={closePanel} />
      <div className={`window ${props.control.isOpen ? '' : 'close-panel'}`}>
        <div className='content-area'>
          <div className='close-button' onClick={closePanel}>
            <X strokeWidth={3} height={55} width={35} />
          </div>
          {props.control.content}
        </div>
      </div>
    </StyledRightPanel>
  )
}

const StyledRightPanel = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  position: fixed;
  z-index:9999;
  width:100vw;
  height:100vh;

  .outside {
    display: flex;
    flex: 1;
    background-color: rgba(255, 255, 255, 0.5);
    min-width: 20px;
    backdrop-filter: blur(2px);
  }

  .window{
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 100vh;
    max-width: ${props => props.control.maxWidth || (props.gutter * 10)}px;
    animation: slide-in 300ms linear forwards;

    &.close-panel {
      animation: slide-out 300ms linear forwards;

      @keyframes slide-out {
        from {
          right: 0;
        }
        to {
          right: -${props => props.control.maxWidth || (props.gutter * 10)}px;
        }
      }
    }

    @keyframes slide-in {
      from {
        right: -${props => props.control.maxWidth || (props.gutter * 10)}px;
      }
      to {
        right: 0;
      }
    }

    .close-button {
      display: flex;
      position: absolute;
      top: ${props => props.control?.topCloseButton ? props.control?.topCloseButton : '16px'};
      right: ${props => props.control?.rightCloseButton ? props.control?.rightCloseButton : '42px'};
      height: 50px;
      width: 50px;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      cursor: pointer;
      user-select: none;
      background-color: #f0f6e0;
      color: #88c929;
      z-index: 9999;

      @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
        width: 45px;
        height: 45px;
        top: 8px;
      }

      svg {
        width: 30px;
        height: 55px;

        @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
          width: 22px;
          height: 45px;
        }
      }
    }

    .content-area {
      display: flex;
      flex: 1;
      background-color: white;
      box-shadow: -20px 0px 100px rgba(0,0,0,0.24);
      overflow: auto;
      @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
        overflow: scroll;
      }
      padding: ${props => props.control?.accessCode ? '0px 20px 0px 10px' : '0'};
    }
  }
`

export default RightPanel;