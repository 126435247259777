import styled from "@emotion/styled";
import { useState, useMemo, useRef } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { BREAKPOINTS } from "../configs/consts";
import ToggleSlider from "../Molecules/ToggleSlider";
import ToolHelper from "../utils/ToolHelper";
import useOutsideAction from "../utils/useOutsideAction";
import CustomLink from "../Molecules/CustomLink";
import CoreLink from "../Molecules/CoreLink";
import { Bell } from "react-feather";

const HeaderDropMenuItem = (props) => {
  const { config, linkProps, checkboxData, openGlobalPopup, targetPopup, hideOn = [], secondary, rightPanel, dataHelper } = props;
  const { customComponent, label } = linkProps;
  const className = useMemo(() => hideOn.join(" "), [hideOn])

  const openPopup = (e) => {
    e.preventDefault();
    openGlobalPopup(targetPopup)
  }

  const DropMenuLink = () => {
    if (checkboxData) {
      return <>
        <span className={'label'} config={config}>{label}</span>
        <ToggleSlider config={config} isChecked={checkboxData.isChecked} onToggle={checkboxData.onToggle} />
      </>
    }

    if (customComponent) {
      return <CustomLink {...props} componentName={customComponent} />
    }

    if (targetPopup) {
      return <Link
        className={'label ' + (secondary ? 'secondary' : '')}
        to={'/'}
        onClick={openPopup}
        config={config}
      >
        {label}
      </Link>
    }

    if (["Indique um amigo", "Campanhas"].includes(linkProps.label)) {
      linkProps.url = null;
    }

    return <CoreLink
      {...linkProps}
      domain={config?.firebaseConfig?.authDomain}
      rightPanel={rightPanel}
      config={config}
      data={props.data}
      dataHelper={dataHelper}
      message={props.message}
      className={'label ' + (secondary ? 'secondary' : '')}
    />
  }

  return <StyledHeaderDropMenuItem className={className} config={config} data={props.data}>
    <DropMenuLink />
  </StyledHeaderDropMenuItem>;
}

const HeaderDropMenu = (props) => {
  const { parentRef, isOpen, toggleIsOpen, menuItems, mobileItems, openGlobalPopup, data, openNotifications, rightPanel, dataHelper, message, userHelper, notification } = props;
  const wrapperRef = useRef(null);
  
  const IS_GROWER = data?.user?.acg_GrowerAccount__r?.acg_IsGrower__c;
  const IS_FARMER = data?.user?.LoyaltyCategory__c === 'Agricultor';

  const closeMenu = () => {
    toggleIsOpen(false);
  }

  useOutsideAction(wrapperRef, closeMenu, parentRef)

  let config = useMemo(() => ToolHelper.getThemedConfig(props, 'HeaderDropMenu.preset'), [props]);

  return <StyledHeaderDropMenu config={config} isOpen={isOpen} ref={wrapperRef}>
    <ul>
      <div className={'main-items'}>
        {menuItems?.map((menuItem) => {

          if (!IS_GROWER && menuItem.label === "Agrupe pontos") {
            return null;
          }

          if ((!IS_FARMER || !IS_GROWER) && menuItem.label === "Transfira pontos") {
            return null;
          }

          return <HeaderDropMenuItem linkProps={menuItem} key={menuItem.label} config={config} data={data} openGlobalPopup={openGlobalPopup} rightPanel={rightPanel} dataHelper={dataHelper} message={message} />
        })}
        {/* {IS_FARMER && (
          <Bell
            width={15}
            height={15}
            color={config.themeLayout?.buttonBackground}
            fill={config.themeLayout?.buttonBackground}
            style={{ marginTop: 10 }}
            onClick={openNotifications}
          />
        )} */}
      </div>
      <div className={'mobile-items'}>
        {mobileItems?.map(menuItem =>
          <HeaderDropMenuItem linkProps={menuItem} key={menuItem.label} config={config} data={data} openGlobalPopup={openGlobalPopup} rightPanel={rightPanel} message={message} dataHelper={dataHelper} />
        )}
      </div>
    </ul>
  </StyledHeaderDropMenu>
}

HeaderDropMenu.propTypes = {
  config: PropTypes.object,
  linkProps: PropTypes.object,
  checkboxData: PropTypes.object,
  openGlobalPopup: PropTypes.func,
  targetPopup: PropTypes.object,
  hideOn: PropTypes.array,
  secondary: PropTypes.bool,
  rightPanel: PropTypes.object,
  dataHelper: PropTypes.object
};

export default HeaderDropMenu;

/* style */
const StyledHeaderDropMenu = styled.div`
  position: absolute;
  background: #FFFFFF;
  right: 0;
  padding: 20px;
  border: solid thin #707070;
  border-radius: 24px;
  z-index: 9999;
  overflow: hidden;
  transition: transform 200ms linear;
  transform-origin: top;
  transform: scaleY(${props => props.isOpen ? 1 : 0});

  @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
    left: 0;
    margin: 10px;
  }

  ul{
    display: flex;

    .mobile-items{
      display: none;

      @media (max-width: ${BREAKPOINTS.PHONE}px){
        display: block;
      }
    }
  }
`;

const StyledHeaderDropMenuItem = styled.li`
  width: 200px;
  padding-right: 20px;

  .label{
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 35px;
    text-transform: uppercase;
    color: #222222;
    cursor: initial;
  }

  a.label{
    width: 100%;
    color: #222222;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.secondary{
      color: #000;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: ${BREAKPOINTS.PHONE_SMALL}px){    
    width: 150px;
  }

  @media (max-width: ${BREAKPOINTS.PHONE}px){    
    &.mobile {
      display: none;
    }
  }
    
  @media (min-width:${BREAKPOINTS.PHONE + 1}px) and (max-width:${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    &.tablet {
      display: none;
    }
  }

  @media (min-width:${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    &.desktop {
      display: none;
    }
  } 
`

HeaderDropMenu.propTypes = {
  data: PropTypes.object,
};