import styled from "@emotion/styled";
import { useMemo } from "react";

const Point = (props) => {
  const { qty, config, small, large, decimal, color, strong } = props;

  const negative = useMemo(() => {
    return qty < 0;
  }, [qty])

  const decimalDigits = useMemo(() => {
    if (decimal !== undefined) {
      return decimal;
    }

    return config?.strings?.POINT_DECIMAL;
  }, [config, decimal])

  const extraClasses = useMemo(() => {
    let result = '';

    if (small) result += ' small';
    if (large) result += ' large';
    if (negative) result += ' negative';
    if (strong) result += ' strong';

    return result;
  }, [small, large, negative])

  return <StyledPoint config={config} color={color} qty={qty} className={extraClasses} data-testid={"point"}>
    {qty !== undefined ?
      <>
        <span className={'signal'}>{negative ? '- ' : ''}</span>
        <span className={'prefix'}>{config?.strings?.POINTS}</span>
        <span className={'qty'}>{Math.abs(qty).toLocaleString('pt', { minimumFractionDigits: decimalDigits, maximumFractionDigits: decimalDigits })}</span>
        <span className={'suffix'}>{config?.strings?.POINTS}</span>
      </> :
      <>
        <span className={'qty-none'}>0 pts</span>
      </>
    }
  </StyledPoint>
}

export default Point;

const StyledPoint = styled.span`
  /* font-weight: bold; */
  color: ${props => props.color || props.config.themeLayout?.title};

  &.negative{
    color: ${props => props.config.themeLayout?.error};
  }

  &.strong{
    font-weight: bold;
  }
  
  .qty{
    font-size: 1.6em;
  }

  .prefix{
    display: ${props => props?.config?.strings?.POINT_POS === 'prefix' ? 'initial' : 'none'};
    margin-right: 0.3em;
  }

  .suffix{
    display: ${props => props?.config?.strings?.POINT_POS === 'suffix' ? 'initial' : 'none'};
    margin-left: 0.3em;
  }

  .qty-none{
    font-size: 2em;
  }

  &.small{
    .qty{
      font-size: 1em;
    }

    .suffix{
      margin-left: 0.1em;
      font-size: 0.8em;
    }

    .qty-none{
      font-size: 3.3em;
    }
  }

  &.large{
    .qty{
      font-size: 2.5em;
    }
  }
`