import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import style, { StyledCheckbox } from './assets/Checkbox_style';

const Checkbox = forwardRef((props, ref) => {
  const [isChecked, setIsChecked] = useState(false);
  const { input, check, config, style: styleProp } = props;

  const styles = style(props);

  useImperativeHandle(ref, () => ({
    reset() {
      setIsChecked(false);

      if (props.onChange) {
        props.onChange();
      }
      else {
        input.inputRef.current.value = 0;
      }
    }
  }))

  const updateValue = (e) => {

    setIsChecked(!isChecked);
    e = {
      ...e,
      isChecked: !isChecked
    }

    if (props.onChange) {
      props.onChange(e, !isChecked);
    }
    else {
      if (input?.inputRef?.current) {
        input.inputRef.current.value = input.inputRef.current.checked ? 1 : 0;
      }
    }
  }

  useEffect(() => {
    setIsChecked(check);
  }, [check])

  const id = props.id || 'checkbox' + Math.floor(Math.random() * 10000);

  return <div style={styles.container}>
    <StyledCheckbox {...props} config={config}>
      <input
        type={'checkbox'}
        id={id}
        ref={input?.inputRef}
        checked={isChecked}
        onChange={updateValue}
        data-testid="checkbox"
      />
      <span style={styleProp}/>
      <span className={'check-text'} dangerouslySetInnerHTML={{__html:input?.label}} />
    </StyledCheckbox>
    <p style={styles.errorMessage}>{input?.error}</p>
  </div>;
})

export default Checkbox;