export const infoCard = [
  {
    label: 'Até 15 dias*',
    value: 15,
    stateName: 'ateQuinze',
    description: "Entre hoje e 15 dias.",
    range: ["hoje", "15"],
  },
  {
    label: 'Até 30 dias*',
    value: 30,
    stateName: 'ateTrinta',
    description: "*A partir de 16 dias, até 30 dias.",
    range: ["16", "30"],
  },
  {
    label: 'Até 60 dias*',
    value: 60,
    stateName: 'ateSessenta',
    description: "*A partir de 31 dias, até 60 dias.",
    range: ["31", "60"],
  },
  {
    label: 'Até 90 dias*',
    value: 90,
    stateName: 'ateNoventa',
    description: "*A partir de 61 dias, até 90 dias.",
    range: ["61", "90"],
  }
];

export const infoOrder = [
  {
    label: 'Data da ação',
    value: 'PointDate__c',
    defaultOrder: 'desc',
  },
  {
    label: 'Data da expiração',
    value: 'ExpirationDate__c',
    defaultOrder: 'desc',
  },
  {
    label: 'Ação',
    value: 'Action__c',
    defaultOrder: 'asc',
  },
  {
    label: 'Descrição',
    value: 'Description__c',
    defaultOrder: 'asc',
  },

  {
    label: 'Pontuação',
    value: 'Points__c',
    defaultOrder: 'desc',
  }
];

export const traducoes = {
  Reversal: 'Estornos',
  Canceled: 'Cancelados',
  Loan: 'Empréstimos',
  Bonus: 'Bônus',
  Earned: 'Pontuações',
  Redeem: 'Resgates',
  Expired: 'Expirações',
  Manual: 'Fornecidos',
  Debt: 'Débitos',
  Reserved: 'Reservados',
  Transfered: 'Transferência',
};

export const formatCPF = (str) => (
  str
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1")
);

export const formatCNPJ = str => (
  str
    ?.replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1")
);


export const infoOrderSurvay = [
  {
    label: 'Título',
    value: '',
    defaultOrder: 'desc',
  },
  {
    label: 'Divisão',
    value: '',
    defaultOrder: 'desc',
  },
  {
    label: 'Data',
    value: '',
    defaultOrder: 'asc',
  },
  {
    label: 'Questões',
    value: '',
    defaultOrder: 'asc',
  },

  {
    label: 'Pontuação',
    value: '',
    defaultOrder: 'desc',
  }
];

export function clearAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
};

export const getWorksheetFormatedData = (data = []) => {
  const wsData = data.map(row => {
    const formattedRow = {};

    Object.keys(row).forEach(key => {
      const value = row[key];

      if (Array.isArray(value)) {
        formattedRow[key] = JSON.stringify(value);
      }
      else if (typeof value === 'number') {
        const numberFormat = Number.isInteger(value) ? "0" : "0.0"
        formattedRow[key] = { v: value, t: "n", z: numberFormat };
      } else {
        formattedRow[key] = value;
      }
    });

    return formattedRow;
  });

  return wsData;
};

const culturas = [
  'Algodão',
  'Amendoim',
  'Arroz',
  'Café',
  'Cana-de-açúcar',
  'Cereais de inverno (trigo, cevada, triticale, centeio e aveia)',
  'Citrus',
  'Feijão',
  'Fumo',
  'Girassol',
  'Hortifrúti',
  'Milho - Safra',
  'Milho - Safrinha',
  'Pastagem',
  'Soja',
  'Sorgo'
];

export const optionsCulturas = culturas.map(cultura => ({
  value: cultura,
  label: cultura
}));
