import React, { useMemo, useState } from "react";

import ToolHelper from "../../utils/ToolHelper";

import CampaignsHeaderModal from '../CampaignsHeaderModal';
import CampaignsImageBackground from "../CampaignsImageBackground";

import Button from "../../Atoms/Button";

import Checkbox from "../../Molecules/Checkbox";

const CampaignsRegulation = (props) => {
  const { config, setCloseModal, campaign, setEnrolled, setModalRegulation, rightPanel } = props
  const [ check, setCheck ] = useState(false)
  const [ disabled, setDisabled ] = useState(false)

  const configBox = useMemo(() => ToolHelper.getThemedConfig(props,'Campaigns.preset'),[config])

  const handleEnrollment = async () => {
    if (check) {
      setDisabled(true)
      // console.log('global.ipAddress', global.ipAddress)
      // const clientIP = await props.userHelper.getClientIP();
      const acceptanceDevice = 'Site';

      const result = await props.dataHelper.enrollCampaign(campaign.campaignId, props.data.user.FirebaseId__c, global.ipAddress ?? "", acceptanceDevice)

      if (result && result.status === 'OK') {
        props.message.add({ type: "success", title: result.message })
        setEnrolled(campaign.campaignId)
        setCloseModal(false)
      } else {
        props.message.add({ type: "error", title: result.message || "Algo deu errado..." })
      }

      setDisabled(false)
    }
  }

  const handleCheck = (e) => {
    setCheck(e.target.checked)
  }

  const handlePdf = () => {
    setModalRegulation(true)
  }

  return (
    <div className="mainContent">
      <CampaignsHeaderModal {...props} setCloseModal={setCloseModal} rightPanel={rightPanel} />
      <div className="mainSection">
        <CampaignsImageBackground config={configBox} name={campaign.name} link={campaign.link} img={campaign.image} />
        <div className="modalTitle">
          <span>Regulamento</span>
        </div>
        {
          <div className="acceptContainer">
            <div style={{display: 'flex', flexDirection: 'row', paddingBottom: '15px'}}>
              <Checkbox onChange={handleCheck} config={configBox} className="checkBoxCamp" style={{borderRadius: '25%'}} size='18px' />
              <div className="acceptDescription">
                <span>Estou de acordo com o&nbsp;</span>
                <span className="spanHigh" onClick={handlePdf}>regulamento</span>
                <span>&nbsp;da campanha.</span>
              </div>
            </div>
            <Button config={configBox} small disabled={disabled} onClick={handleEnrollment} size="140px">
              {config?.strings?.screen?.campaignsAgreement?.ACCEPT}
            </Button>
          </div>
        }
      </div>
    </div>
  )
}

export default CampaignsRegulation;