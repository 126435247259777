import React, {useMemo, useState, useEffect} from "react";

import QRCode from "react-qr-code";
import { Copy, Share2 } from "react-feather";
import moment from 'moment';
  
import SideTitle from "../Atoms/SideTitle";
import ToolHelper from '../utils/ToolHelper';
import MemberGetMember from '../assets/MemberGetmember.svg';
import LoadingIcon from '../Atoms/LoadingIcon';

import { StyledNotifications } from "./styled";

const MGM = (props) => {
  const { dataHelper, data } = props;

  const config = useMemo(() => ToolHelper.getThemedConfig(props, 'MemberGetMember.preset'), [props.config])

  const [ loading, setLoading ] = useState(true);
  const [ code, setCode ] = useState("");
  const [ url, setUrl ] = useState("");
  const [ msg, setMsg ] = useState("");
  const [ htmlTitle, setHtmlTitle ] = useState("")
  const [ baseDeeplinkUrl, setBaseDeeplinkUrl ] = useState("");
  const [ memberCodeCampaign, setMemberCodeCampaign ] = useState([])

  const getPageTitle = async () => {
    try {
      const html = await dataHelper.userHelper.getMGMPageTitle()
      if (html?.indexOf("<p>") > 0) {
        setHtmlTitle(html);
      } else {
        setHtmlTitle(`<p>${html}</p>`);
      }
    } catch (error) {
      
    }
  };

  const getEnv = () => {
    let urlReturn = ''
    switch (config.firebaseConfig.projectId) {
      case "syngenta-dev-c4409":
        urlReturn = 'https://syngentaaliado.page.link/cadastro-completo'
        break;
      case "syngenta-homol":
        urlReturn = 'https://syngentahomol.page.link/cadastro-completo'
        break;
      case "syngenta-1229a":
        urlReturn = 'https://syngentaacessaagro.page.link'
        break;
      default:
        break;
    }

    setBaseDeeplinkUrl(urlReturn)
    return urlReturn
  }

  const getMemberCodeCampaign = async () => {
    try {
      const now = moment()
      const result = await dataHelper.userHelper.getMemberCodeCampaigns()
      if(result && result.data) {
        let filteredResult = result.data.filter((item) => moment(item.acg_EndDate__c) >= now)
        if (filteredResult) {
          setMemberCodeCampaign(filteredResult)
          await getGenericInfo(filteredResult)
        }
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const getGenericInfo = async (info) => {
    try {
      let newInfo = info.reduce((a, b) => {
        a.push(b.Id)
        return a
      }, []).join(';')
  
      const result = await dataHelper.getGenericInfo("acg_MemberCodeCampaign__c", "acg_TargetGroup__c", "Id", data?.user?.FirebaseId__c, "cgny2__FirebaseId__c", newInfo, null)
      if (result.length) {
        const deeplinkUrl = getEnv()
  
        const referCode = data?.user?.ReferralCode__c
        setCode(referCode)
  
        const encodedUrl = encodeURIComponent(`https://www.acessaagro.com.br/cadastro-completo?referralCode=${referCode}`)
        const finalUrl = `${deeplinkUrl}/?link=${encodedUrl}&apn=br.com.valuenet.syngentaaliado&isi=1441252658&ibi=br.com.valuenet.syngentaaliado&efr=1`
  
        // short link
        const shortLink = await dataHelper.shortenUrl(finalUrl)

        setMsg('Olá!\n\n' +
        'Indiquei você para o Acessa Agro!🙂 Um programa de fidelidade feito especialmente para o produtor rural.\n' +
        `Cadastre-se usando meu código ${referCode}\n\n` +
        `Baixe o app pelo link: ${shortLink?.shortUrl ? shortLink?.shortUrl : finalUrl}`)

        setUrl(shortLink?.shortUrl ? shortLink?.shortUrl : finalUrl)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      
    }
  }

  useEffect(() => {
    getPageTitle()
    getMemberCodeCampaign()
  }, [])

  const copyCode = () => {
    navigator.clipboard.writeText(code)
    props.message.add({ body: code, type: "success", title: "Código copiado" })
  }

  const copyMessage = () => {
    navigator.clipboard.writeText(msg)
    props.message.add({ body: msg, type: "success", title: "Mensagem copiada" })
  }

  return (
    loading ? <LoadingIcon config={config} /> :
    <StyledNotifications config={config}>
      <SideTitle config={config}>Indique um amigo</SideTitle>
      <div className="mainSection">
        <div className="divImage">
          <img src={MemberGetMember} alt="Member get member" />
        </div>
        {htmlTitle && 
          <div className="divImage" dangerouslySetInnerHTML={{__html: htmlTitle}} data-testid="htmlTitle" />
        }
        <div className="card">
          <span className="number">{code}</span>
          <div className="action" onClick={copyCode} data-testid="copyCode">
            <span>Copiar</span>
            <Copy color={config?.themeLayout?.buttonBackground} height="24px" width="24px" />
          </div>
        </div>
        <div className="card">
          <QRCode value={url} size={128}/>
          <div className="action" onClick={copyMessage} data-testid="qrCode">
            <span>Compartilhar nas redes</span>
            <Share2 color={config?.themeLayout?.buttonBackground} height="24px" width="24px" />
          </div>
        </div>
      </div>
    </StyledNotifications>
  )
}

export default MGM;