/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, Suspense, useContext } from 'react';
import { Router, useHistory, useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { onMessage } from "firebase/messaging";

import HeaderV2 from '../Header/HeaderV2.js';
import UserHelper from '../utils/UserHelper';
import DataHelper from '../utils/DataHelper';
import ToolHelper from '../utils/ToolHelper';
import MessageHelper from '../utils/MessageHelper';
import Loader from '../Molecules/Loader.js';
import Popup from '../Molecules/Popup.js';
import RightPanel from '../Molecules/RightPanel';
import NotificationContainer from '../Molecules/NotificationContainer.js';
import FloatingMessageContainer from '../Molecules/FloatingMessageContainer.js';
import DeclareRoutes from '../routes/DeclareRoutes.js';
import Seo from '../routes/Seo.js';
import ReactGA from 'react-ga4';
import GlobalPopup from '../GlobalPopup/index.js';
import ModalPopup from './ModalCompleteInfo.js';

import AppAdSectionV2 from '../Banner/AppAdSectionV2';
import FooterLinks from '../Molecules/FooterLinks';

import { getRemoteConfig } from "firebase/remote-config";
import Context from '../../context/Context.js';
import { useApiContext } from '../../config/hooks/useApiContext.js';
import PrivacyCookiesPolicy from '../PrivacyCookiesPolicy';
import { PROFILES } from './consts.js';

const CoreLoader = (props) => {
  let { configFiles, match } = props;
  let app = initializeApp(configFiles.firebaseConfig);
  const remoteConfig = getRemoteConfig(app);
  remoteConfig.settings.minimumFetchIntervalMillis = 0;

  if (configFiles.firebaseConfig.trackingId || configFiles.firebaseConfig.measurementId) {
    ReactGA.initialize(configFiles.firebaseConfig.trackingId || configFiles.firebaseConfig.measurementId);
  }

  const { apiService, setUserInfo } = useContext(Context);

  let db = getFirestore(app);
  let userHelper = useMemo(() => new UserHelper(app, db, configFiles.getConfigFile), []);
  let dataHelper = useMemo(() => new DataHelper(app, db, configFiles.getConfigFile), []);
  userHelper.setDataHelper(dataHelper);
  dataHelper.setUserHelper(userHelper);

  useApiContext(apiService, dataHelper)

  const history = useHistory();
  const location = useLocation();
  const popup = ToolHelper.usePopup();
  const rightPanel = ToolHelper.useRightPanel();
  const notification = MessageHelper.useNotification();
  const message = MessageHelper.useMessage();

  const [cases, setCases] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [howToEarns, setHowToEarns] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [news, setNews] = useState([]);
  const [banners, setBanners] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [ytVideos, setYTVideos] = useState([]);
  const [unsubFunctions, setUnsubFunctions] = useState({});
  const [user, setUser] = useState({});
  const [startupMessage, setStartupMessage] = useState({});
  const [hasAuthResponse, setHasAuthResponse] = useState(false);
  const [screenProps, setScreenProps] = useState({});
  const [isLoadedSnapshots, setIsLoadedSnapshots] = useState(false);
  const [doLoadSnapshots, setDoLoadSnapshots] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [authObserver, setAuthObserver] = useState(null);
  const [clientLocation, setClientLocation] = useState({ city: 'São Paulo', region: 'State of São Paulo', region_code: 'SP' });
  // const [strings,setStrings] = useState({});
  const [config, setConfig] = useState({
    appconfig: configFiles.APPCONFIG,
    layout: configFiles.LAYOUT,
    strings: '',
    presets: configFiles.PRESETS,
    colors: configFiles.COLORS,
    theme: configFiles.THEME,
    logo: configFiles.logo,
    placeholder: configFiles.placeholder,
    Userform: configFiles.Userform,
    Caseform: configFiles.Caseform,
    firebaseConfig: configFiles.firebaseConfig,
    firebaseRemoteConfig: remoteConfig
  });

  const unsubscribe = () => {
    if (unsubFunctions && Object.keys(unsubFunctions).length > 0) {
      let unsubArray = Object.entries(unsubFunctions);

      unsubArray.forEach((unsub) => {
        if (unsub[1] && typeof unsub[1] === 'function') {
          unsub[1]();
        }
      });

      setDoLoadSnapshots(false);
      setIsLoadedSnapshots(false);
      setUnsubFunctions({});
      setUser({});
      setUserInfo(null);
    }

    navigator.serviceWorker.removeEventListener('message', windowMessageHandler)
  }

  const windowMessageHandler = (params) => {
    if (params?.data?.messageType === 'notification-clicked') {
      notification.add(params.data.notification, params.data.data)
    }
  }

  useEffect(() => {
    changeScreenProps();
  }, [user, challenges, milestones, howToEarns, banners, startupMessage, rewards, news, trainings, ytVideos, config, showHeader, clientLocation])



  const logoutUser = (logout) => {
    logout && history.push('/logout');
  }

  const checkUserProfile = async (user) => {
    ToolHelper.checkProfile(user?.acg_ProfileCodes__c, history);
  }

  useEffect(async () => {
    if (Object.keys(user).length === 0 || !dataHelper?.api) return;
    user?.Id && await userHelper.setUserType(user);
    await checkUserProfile(user)

    const isUserActive = user?.acg_Status__c ? user?.acg_Status__c?.toLowerCase() === 'ativo' : true;
    const resultUserFirebaseId = await userHelper.snapUserFirebaseId();

    if (!user?.AccessCodeRequireValidation) {
      logoutUser(!isUserActive);

      if ((!isUserActive) && user?.acg_PremmiarId__c) {
        dataHelper.inativeAllSession(user?.acg_PremmiarId__c);
      }

      if (resultUserFirebaseId?.PasswordDefinitionIsRequired) {
        await userHelper.setAccesCodeRequireTrue();
        history.push(configFiles.routesO.accessCode.path, { UserFirebaseId: resultUserFirebaseId?.UserFirebaseId, username: user?.CNPJ_CPF__c, PasswordDefinitionIsRequired: true });
      }
    }
  }, [user])

  useEffect(() => {
    loadStrings();
    loadThemeConfig();
  }, [global.userType])

  useEffect(() => {
    if (user?.Id) {
      // TODO - É necessário organizar esta chamada com outras chamadas que dependem do userType.
      // Além disso, estas chamadas precisam de unsubscribe e precisam respeitar isLoadedSnapshots e doLoadSnapshots
      let ta = async () => {
        let tt = (await ToolHelper.importByUserType('db/news', configFiles.getConfigFile)).default;
        // console.log('snap by user type')
        dataHelper.snapConfig(tt, (list) => {
          setNews(list);
        })
      }
      ta();
      // ------------------------------------------
    }
  }, [user.Id])

  useEffect(() => {
    setTimeout(() => {
      ReactGA?.send("pageview");
    }, 200)

    const route = ToolHelper.getRouteByLocation(location, configFiles.routesO);

    const currentUser = getAuth().currentUser
    const fetchToken = async () => {
      const accessToken = await currentUser.getIdToken();
      await dataHelper?.api.setUserToken(accessToken)
    }

    if (dataHelper?.api && route?.access === 'logged' && currentUser) { fetchToken() }

    setHasAuthResponse(false);
    startAuthObserver();
    message.closeAllPageMessages();


  }, [location.pathname])

  useEffect(async () => {
    if (user?.Id || user?.UID) {
      const route = ToolHelper.getRouteByLocation(location, configFiles.routesO);

      global.needsToValidateAccessCode = false;
      global.needsToValidateTermsAgreement = false;

      if (route?.access !== 'public') {
        // Tem código de acesso?
        if (
          location.pathname !== configFiles.routesO.accessCode.path &&
          !(await userHelper.isAccessCodeValid(user))
        ) {
          console.log("needsToValidateAccessCode", user)
          global.needsToValidateAccessCode = true;
          history.push(configFiles.routesO.accessCode.path, { UserFirebaseId: user.UserFirebaseId, username: user.CNPJ_CPF__c });
          setHasAuthResponse(true);
          return;
        }

        // Precisa aceitar termos?
        if (
          location.pathname !== configFiles.routesO.caseNew.path &&
          location.pathname !== configFiles.routesO.termsAgreement.path &&
          await userHelper.isRequiredTermsAgreement(user)
        ) {
          global.needsToValidateTermsAgreement = true;
          history.push(configFiles.routesO.termsAgreement.path);
          setHasAuthResponse(true);
          return;
        }
      }
      setHasAuthResponse(true);
    }
  }, [user.Id, user.UID, location.pathname])

  useEffect(() => {
    loadClientLocation();
    if (navigator?.serviceWorker && dataHelper.api) {
      navigator.serviceWorker.addEventListener('message', windowMessageHandler);

      onMessage(userHelper.getMessaging(), (payload) => {
        notification.add(payload.notification, payload.data)
      });

      return () => unsubscribe();
    }
  }, [])

  useEffect(() => {
    if (!isLoadedSnapshots && doLoadSnapshots && dataHelper.api && getAuth().currentUser) {
      // console.log('already has user logged -->', )
      loadSnapshots();
    }
  }, [doLoadSnapshots])

  const loadThemeConfig = async () => {
    const importedFile = await ToolHelper.importByUserType('layout/theme', configFiles.getConfigFile);
    setConfig(c => { c.theme = importedFile.default; return c; });
  }

  const loadStrings = async () => {
    const importedFile = await ToolHelper.importByUserType('basics/strings', configFiles.getConfigFile);
    setConfig(c => { c.strings = importedFile.default; return { ...c } })
  }

  const loadUnloggedSnapshots = () => {
    let unsubT = {};

    unsubT = {
      ...unsubT, ...dataHelper.snapBanners((list) => {
        setBanners(list);
      })
    };

    setUnsubFunctions(us => ({ ...us, ...unsubT }));
  }

  const startAuthObserver = async () => {
    if (!authObserver) {
      const authState = onAuthStateChanged(getAuth(), async () => {
        let userRef = await userHelper.getUserFirebaseId();

        if (userRef) {
          global.isAuthenticated = true;
          await userHelper.setLastTimeOnline();

          if (!isLoadedSnapshots) {
            setDoLoadSnapshots(true);
          }
        }
        else {
          setHasAuthResponse(true);
          global.isAuthenticated = false;

          loadUnloggedSnapshots();
        }
      })
      setAuthObserver(authState);
      setUnsubFunctions(us => { us['authState' + new Date().getTime()] = authState; return us; });
    }
  }

  const loadSnapshots = async () => {
    let unsubT = {};

    await dataHelper.loadCurrentSeasonId();

    unsubT = {
      ...unsubT, ...await userHelper.snapUser((data) => {
        setUser(data);
        setUserInfo(data);
        global.user = data;
      })
    };

    unsubT = {
      ...unsubT, ...await userHelper.snapCases((data) => {
        setCases(data);
      })
    };

    unsubT = {
      ...unsubT, ...dataHelper.snapMilestones((list) => {
        setMilestones(list);
      })
    };

    unsubT = {
      ...unsubT, ...await dataHelper.snapChallenges((list) => {
        setChallenges(list);
      })
    };

    unsubT = {
      ...unsubT, ...dataHelper.snapHowToEarns((list) => {
        setHowToEarns(list);
      })
    };

    unsubT = {
      ...unsubT, ...dataHelper.snapRewards((list) => {
        setRewards(list);
      })
    };

    unsubT = {
      ...unsubT, ...dataHelper.snapBanners((list) => {
        setBanners(list);
      })
    };

    unsubT = {
      ...unsubT, ...dataHelper.snapStartUpMessages((list) => {
        setStartupMessage(list);
      })
    };

    unsubT = {
      ...unsubT, ...dataHelper.snapYTVideos((list) => {
        setYTVideos(list);
      })
    };

    unsubT = {
      ...unsubT, ...dataHelper.snapTrainings((list) => {
        setTrainings(list);
      })
    };

    unsubT = {
      ...unsubT, ...{
        invoices: userHelper.snapInvoices((list) => {
          setInvoices(list);
        })
      }
    };

    setIsLoadedSnapshots(true);
    setDoLoadSnapshots(false);
    setUnsubFunctions(us => ({ ...us, ...unsubT }));
  }

  const openGlobalPopup = (popupName) => {
    GlobalPopup(screenProps, popupName);
  }

  const changeScreenProps = () => {
    setScreenProps({
      match,
      math: Math.random(),
      setShowHeader,
      userHelper,
      dataHelper,
      history,
      unsubscribe,
      routes: configFiles.routesO,
      data: {
        user,
        cases,
        milestones,
        challenges,
        howToEarns,
        banners,
        startupMessage,
        rewards,
        news,
        trainings,
        ytVideos,
        showHeader,
        invoices,
        clientLocation
      },
      config,
      popup,
      openGlobalPopup,
      rightPanel,
      notification,
      message,
      getConfigFile: configFiles.getConfigFile,
    })
  }

  const loadClientLocation = async () => {
    const ip = await userHelper.getClientIP();
    if (ip) {
      try {
        const result = await dataHelper.clientLocation(ip);
        if (result && result.success) {
          const { city, region, region_code } = result.data
          setClientLocation({ city, region, region_code });
        }
      } catch (error) {
        console.log('loadClientLocation::error -->', error)
      }
    }
  }

  useEffect(() => {
    const profiles = user?.acg_LoyaltyProfiles__c ? user?.acg_LoyaltyProfiles__c?.split(';') : [];

    if (user?.LoyaltyCategory__c === 'Time de vendas do canal' && profiles?.includes('Focal Point') && !user?.acg_ESocial__c && history?.location?.pathname !== '/meu-cadastro' && history?.location?.pathname !== '/logout' && history?.location?.pathname !== '/conferencia-termos-de-uso') {
      openPopup();
    }
  }, [user, history?.location?.pathname]);

  const openPopup = (tax_id) => {
    popup.setContent(<ModalPopup user={user} config={config} history={history} popup={popup} />);
    popup.setIsOpen(true);
  };

  const dontShowFooter = () => {
    if (location.pathname.startsWith("/1") || location.pathname.startsWith("/termos-de-uso")) {
      return true
    }

    const routeName = props.configFiles?.routes?.filter(r => r.path === location.pathname)
    if (routeName) {
      return routeName[0]?.dontShowFooter ? true : false
    }
    return false
  }

  return !dataHelper.api ? <></> :
    <>
      <Seo location={location} configFiles={configFiles} />
      {!hasAuthResponse ?
        <Loader /> :
        <Router history={history}>
          <Suspense fallback={<Loader />}>
            <div id={'core-loader-main'} style={{ overflowX: 'hidden', display: 'flex', flexDirection: 'column', height: '100vh', fontFamily: config?.layout?.FONT_FAMILY, fontSize: config?.layout?.FONT_BASE_SIZE }}>
              <HeaderV2 {...screenProps} />
              <DeclareRoutes screenProps={screenProps} routes={configFiles.routes} routesO={configFiles.routesO} />
              <Popup forceOpen={user?.LoyaltyCategory__c === 'Time de vendas do canal' && !user?.acg_ESocial__c} control={popup} />
              <RightPanel control={rightPanel} />
              <NotificationContainer control={notification} config={config} />
              <FloatingMessageContainer control={message} config={config} />
              {!dontShowFooter() &&
                <footer className='footer'>
                  <AppAdSectionV2 {...screenProps} spaced />
                  <FooterLinks {...screenProps} />
                </footer>
              }
              <PrivacyCookiesPolicy {...screenProps} />
            </div>
          </Suspense>
        </Router>
      }
    </>
}

export default CoreLoader;