import styled from '@emotion/styled'

const StyledExternalLink = styled.a`
  cursor: pointer;
  font-weight: bold;

  color: ${props => {
    switch (props.type) {
      default:
        return props.config?.themeLayout?.link;
    }
  }};

  font-size: ${props => {
    switch (props.size) {
      case 'l':
        return '1.1em';
      case 's':
        return '0.9em';
      case 'm': default:
        return '1em';
    }
  }};

  ${props => props.style}
`;

export default StyledExternalLink;