const NO_RETURN_JSON_OR_TEXT = ['/addCase', '/premmiar/updateUser', '/deleteInvoice']

class ApiService {
  constructor(baseURL) {
    this.baseURL = baseURL
    this.globalUID =  global?.user?.UID ||global?.user?.uID;
  }

  setBaseUrl(url) {
    this.baseURL = url
  }

  setUserToken(token) {
    this.token = token
  }

  async request(endpoint, method = 'GET', data = null, headers = {}, returnJson = true, token = null) {
    let url;

    if (token) {
      url = endpoint;
    } else {
      url = `${this.baseURL}${endpoint}`;
    }

    const config = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token || (this.token ?? ""),
        'x-request-user':  this.globalUID || null,
        ...headers
      }
    };

    if (data) {
      config.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, config);
      if (!response.ok) {
        if ([401, 403].includes(response.status)) {
          const errorJson = await response.json()
          return errorJson;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const filteredReturn = NO_RETURN_JSON_OR_TEXT.filter(function (str) {
        return endpoint.includes(str);
      });

      if (filteredReturn?.length) {
        return { success: true }
      } else {
        const ret = returnJson ? await response.json() : await response.text();
        try {
          return ret
        } catch (error) {
          console.log('1:ApiService::error -->', error, ret)
        }
        return
      }
    } catch (error) {
      console.log('2:ApiService::error -->', endpoint, error)
      throw error;
    }
  }

  get(endpoint, returnJson = true, headers = {}, token = null) {
    return this.request(endpoint, 'GET', null, headers, returnJson, token);
  }

  post(endpoint, data, returnJson = true, headers = {}) {
    return this.request(endpoint, 'POST', data, headers, returnJson);
  }

  put(endpoint, data, returnJson = true, headers = {}) {
    return this.request(endpoint, 'PUT', data, headers, returnJson);
  }

  delete(endpoint, returnJson = true, headers = {}) {
    return this.request(endpoint, 'DELETE', null, headers, returnJson);
  }
}

export default ApiService;